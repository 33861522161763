#root {
  .form-image {

    text-align: center;

    img {
      min-height: 140px;
      max-height: 240px;
      max-width: 100%;
      border-radius: 1.2rem;
    }
  }
}
