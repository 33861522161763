#header {
  border-radius: 1.2rem;
  padding: 0.25rem 0.5rem;
  background-image: url("https://static.matthys-wines.com/services/dustlake/dustlake/dust-lake-glacier.png");
  background-size: cover;
  background-position: top center;

  .btn.btn-header-menu {
    font-weight: 500;
    border-color: transparent;
    background: transparent;
    color: white;
  }

  .btn.btn-header-menu:hover, .btn.btn-header-menu:active {
    font-weight: 500;
    border-color: #033e43;
    background: #033e43;
    color: white;
  }

  .btn.btn-navbar {
    color: #033e43;
    border-color: white;
  }

  .btn.btn-navbar:hover, .btn.btn-navbar:active {
    color: white;
    background-color: #037575;
    border-color: #037575;
  }

  .btn.btn-menu-close {
    position: absolute;
    right: 10px;
    border-color: #033e43;
    color: #033e43;
    top: 10px;
  }

  .company-logo {
    margin: 0.25rem 0.5rem 0.25rem 0.75rem;
    width: 28px;
    height: 28px;
    background: url("./company-icon.svg") no-repeat center center;
  }

  .menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    overflow-y: scroll;
    justify-content: center;
    padding-top: 4rem;
  }

  .company-logo-mobile {
    margin-top: 0.2rem;
    width: 28px;
    height: 28px;
    background: url("./company-icon.svg") no-repeat center center;
  }

  .company-name {
    color: white;
    font-weight: 500;
  }

  .user-name-div {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .user-name {
    color: white;
    font-weight: 500;
  }

  .btn.btn-link:focus {
    box-shadow: 0 0 0 0.1rem rgba(255, 255, 255, 0.3);
    outline: white;
  }
}
