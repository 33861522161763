
#authentication {
  background-image: url("https://static.matthys-wines.com/services/dustlake/dustlake/dust-lake-glacier.png");
  min-height: 100vh;
  background-size: cover;
  background-position: center center;

  .btn.btn-authenticate {
    padding: 0.5rem 1.5rem;
    border-color: transparent;
    background: white;
    color: #121c21;
  }

  .btn.btn-authenticate:hover {
    color: white;
    border-color: #033e43;
    background: #033e43;
  }

  .btn.btn-authenticate:active {
    color: white;
    border-color: #022929;
    background: #022929;
  }

  .company-logo {
    flex-grow: 1;
    margin: 4rem auto 0 auto;
    width: 200px;
    height: 100px;
    background: url("./company.svg") no-repeat center center;
    color: white;
    text-align: center;
  }

  .auth-container {
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
  }

  .auth-body {
    flex-grow: 1;
  }

  .dust-lake-logo {
    margin: 48px auto;
    width: 40px;
    height: 40px;
    background: url("./dust_lake_icon.svg") no-repeat center center;
  }

}
