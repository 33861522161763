@keyframes loader-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.loader-wrapper, .loader-inline, .loader-inline-small {
  .loader-body {
    align-self: center;
    position: relative;
    width: 95px;
    height: 95px;

    .spinner-center {
      position: absolute;
      left: 40px;
      top: 40px;
      height: 15px;
      width: 15px;
      background: url("./spinner-center-01.svg");
    }

    .spinner-outer {
      animation-name: loader-spin;
      animation-duration: 2500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      height: 95px;
      width: 95px;
      background: url("./spinner-outer-01.svg");
    }
  }
}

.loader-inline-small {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  opacity: 0;
  animation-name: loaderFadeIn;
  animation-duration: 500ms;
  animation-delay: 100ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;

  .loader-body {
    align-self: center;
    position: relative;
    width: 40px;
    height: 60px;

    .spinner-center {
      animation-name: loader-spin;
      animation-duration: 2500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      left: 0;
      top: 10px;
      height: 40px;
      width: 40px;
      background: url("./black-hole.svg");
    }
  }
}

@keyframes loaderFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader-inline {
  padding: 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  opacity: 0;
  animation-name: loaderFadeIn;
  animation-duration: 500ms;
  animation-delay: 1000ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
