table.subscriptions-table {

  width: 100%;
  border-collapse: collapse;

  .btn.btn-link {
    padding: 0;
    height: auto;
  }

  th {
    text-align: left;
    padding: 0.5rem;
    border-bottom: 1px solid #eeeeee;
  }

  td {
    padding: 0.2rem 0.5rem;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
  }

}

#subscriptionListPage {
  h3 {
    margin-top: 1.5rem;
  }
}
