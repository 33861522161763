#side-menu {

  padding: 0.5rem;
  border-radius: 1.2rem;

  .company-name {
    font-weight: 500;
  }

  .accordion {
    margin-bottom: 1rem;

    li.nav-item:last-child {
      margin-bottom: 0.5rem;
    }
  }

  margin-left: 0.3rem;

  .nav-item {
    font-size: 0.75rem;

    a {
      margin-left: 0.8rem;
      color: #444;
      padding: 0.2rem 0.7rem;
      border-radius: 1.2rem;
    }

    a:hover {
      background: #ddd;
    }
  }

  .nav-header {
    display: inline;
    border-radius: 1.2rem;
    padding: 0.4rem 1rem 0.4rem 0.8rem;
    cursor: pointer;
    color: #022929;
    font-weight: 500;
    font-size: 0.9rem;

    svg {
      width: 24px;
    }
  }

  .nav-dashboard {
    display: inline-block;
    margin-bottom: 1rem;
  }

  .nav-header:hover {
    background: #ddd;
  }

  .nav-icon {
    margin-right: 0.5rem;
    font-size: 0.8rem;
  }

  .nav-item-link {
    margin-left: 1rem;
    font-weight: 500;
  }
}
