.image-drop {
  border: 3px solid #eeeeee;
  border-radius: 0.8rem;
  background-repeat: no-repeat;
  position: relative;
  background-image: url("./upload.svg");
  background-size: 32px;
  background-position: center 24px;

  .dropzone-input {
    width: 100%;
    min-height: 100px;
  }

  .dropzone-label {
    color: #999999;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 10px;
  }

  .loader-body {
    margin: 1rem;
  }
}

.image-drop.image-loading {
  background-image: none;
}

.image-drop.hover {
  border-color: #037575;
}
