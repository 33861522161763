table.orders-table {

  width: 100%;
  border-collapse: collapse;

  .btn.btn-link {
    padding: 0;
    height: auto;
  }

  th {
    font-size: 0.7rem;
    text-align: left;
    padding: 0.5rem;
    border-bottom: 1px solid #eeeeee;
  }

  td {
    font-size: 0.7rem;
    padding: 0.5rem 0.5rem;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
  }

}
