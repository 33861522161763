
#horecaPage {
  .horeca-table-container {
    max-height: 600px;
    overflow-y: scroll;
  }

  table.horeca-members-table {
    width: 100%;
    border-radius: 1.2rem;
    border-collapse: collapse;

    .btn.btn-link {
      padding: 0;
      height: auto;
    }

    th {
      text-align: left;
      padding: 0.5rem;
    }

    td {
      padding: 0.2rem 0.5rem;
      border: none;
    }

    tr:not(:last-child) td {
      border-bottom: 1px solid #bcc3ce;
    }

  }

}
