#root {

  h1, h2, h3, h4, h5 {
    font-family: 'Work Sans', sans-serif;
  }

  h1 {
    font-size: 1.6rem;
    font-weight: 500;
    @media screen and (max-width: 720px) {
      font-size: 1.2rem;
    }
  }

  .form-input.form-input-resize {
    min-height: 36px;
    border-radius: 8px;
    min-width: 100%;
  }

  .toast {
    border-radius: 1.2rem;
    padding: 0.4rem 0.8rem;
  }

  .editor-label {
    line-height: normal;
    @media screen and (max-width: 720px) {
      font-size: 0.6rem;
    }
  }

  .form-label-lead {
    color: #7d7d7d;
    font-size: 0.6rem;
    @media screen and (max-width: 1080px) {
      font-size: 0.5rem;
    }
  }

  .form-description {
    color: #7d7d7d;
    font-size: 0.7rem;
  }

  .btn:not(.btn-link) {
    border-radius: 30px;
    height: auto;
    padding: 0.25rem 1rem;
  }

  .text-cross {
    text-decoration: line-through;
  }

  input.form-input, select.form-select {
    border-radius: 8px;
  }

  .btn:not(.btn-link):focus {
    box-shadow: 0 0 0 0.1rem rgba(15, 147, 156, 0.5);
  }

  .btn:not(.btn-link) {
    color: #033e43;
    background: white;
    border-color: #033e43;
  }

  .btn:not(.btn-link):hover {
    color: white;
    background-color: #037575;
    border-color: #037575;
  }

  .btn:not(.btn-link):active {
    color: white;
    border-color: #022929;
    background: #022929;
  }

  .btn.btn-primary {
    color: white;
    background-color: #033e43;
    border-color: #033e43;
  }

  .btn.btn-primary:hover {
    color: white;
    background-color: #037575;
    border-color: #037575;
  }

  .btn.btn-primary:active {
    color: white;
    border-color: #022929;
    background: #022929;
  }

  .app-container {
    padding: 1rem;
  }

  .editor-container {
    min-height: calc(100vh - 200px);
    padding: 0 1rem;

    .editor-float {
      padding: 1rem 0;
    }

    .editor-footer {
      padding-bottom: 1rem;
    }

    .editor-padding {
      padding: 0 1.2rem;
    }

    .editor-page {
      border-radius: 1rem;
      padding: 0.9rem 1.2rem;
      background-color: white;
      margin-bottom: 1rem;

      .page-divider {
        border-bottom: 1px solid #eeeeee;
        margin: 0.5rem -0.6rem;
      }
    }

    .pagination {
      justify-content: center;
    }

    .nav {
      .nav-item {
        margin-top: 0;
        padding: 0.4rem 0;
      }

      .nav-item:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
    }
  }

  .nav.nav-large {
    .nav-item {
      font-size: 1rem;
      background-color: white;
      padding: 1rem;
      border-radius: 1.2rem;
    }
  }

  .mt-3 {
    margin-top: 1rem;
  }
}

body {
  background: #eeeeee !important;
  font-family: 'Open Sans', sans-serif;
}
