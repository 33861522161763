#root {


  .btn.btn-circle {
    padding: 0.1rem 0.4rem;
    font-size: 0.7rem;
  }

  .btn.btn-circle.btn-red {
    border-color: #c23616;
    color: #c23616;
  }

  .btn.btn-circle.btn-red:hover {
    border-color: #e84118;
    background-color: #e84118;
    color: white;
  }

  .btn.btn-circle.btn-red:active {
    border-color: #e84118;
    background-color: #c23616;
    color: white;
  }

  .btn.btn-circle.btn-red:focus {
    box-shadow: 0 0 0 0.1rem rgba(232, 65, 24, 0.4);
  }

  .form-input-document {
    width: 100%;

    td:last-child {
      text-align: right;
    }
  }

  .input-countries {
    max-height: 240px;
    overflow-y: auto;
  }
}
