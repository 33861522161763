#namespace {
  .key-container {

    .key-name {
      margin-bottom: 0.4rem;
    }

    padding: 0.5rem;
    margin-bottom: 0.3rem;

    .key-language {
      padding: 0.15rem 0;
    }

    .key-language-code {
      .key-language-name {
        width: 32px;
        text-align: center;
      }

      align-self: center;
    }

  }
}
